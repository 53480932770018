// export const ROOT = "https://test-api.certifi.biz";
export const ROOT = "https://apisyn.certifi.biz";
// export const ROOT = 'https://api.certifi.biz'

export default {
	LOGINURL: ROOT + "/login/",
	REGISTERURL: ROOT + "/signmeup_tmp/",
	AggreementVerify: ROOT + "/registerplans/",
	SecurityQuestionURL: ROOT + "/getsecqueslist/",
	RESETPASSWORD: ROOT + "/resetpasswordmail/",
	RESETNEWPASSWORD: ROOT + "/resetpassword/",
	EMAILVERIFIED: ROOT + "/email/verify/",
	WHITELISTEMAILVERIFIED: ROOT + "/email/whitelist/",
	GetInbox: ROOT + "/getinbox/",
	GetFailedSMS: ROOT + "/getfailedSMS/",
	GetFailedMail: ROOT + "/getfailedmails/",
	GetSuccessSMS: ROOT + "/getsuccessSMS/",
	GetUnprocessSMS: ROOT + "/getunprocsms/",
	GetSuccessMail: ROOT + "/getsuccessmails/",
	GetUnprocessMail: ROOT + "/getunprocmails/",
	GetDisApprovedMail: ROOT + "/getdisapprovedmails/",
	GetProfileApi: ROOT + "/personal_info/",
	GetTariffplans: ROOT + "/trafficplans/",
	GetPaymentHistory: ROOT + "/getpaymenthistory/",
	GetCreditCharge: ROOT + "/getcreditchargelist/",
	ViewInvoice: ROOT + "/viewinvoice/",
	Compose: ROOT + "/composesmsotp/",
	SendSMS: ROOT + "/sendsms/",
	ComposeOTPverify: ROOT + "/verifysmssessionotp/",
	ApproveEmail: ROOT + "/approve/",
	DissApproveEmail: ROOT + "/unprocmail/",
	DissApproveSMS: ROOT + "/unprocsms/",
	DisApproveEmail: ROOT + "/reject/",
	updatereferralcode: ROOT + "/updatereferralcode/",
	changeApprovalType: ROOT + "/changeApprovalType/",
	uploadmypic: ROOT + "/uploadmypic/",
	setmymobilenum: ROOT + "/setmymobilenum/",
	changeaddr: ROOT + "/changeaddr/",
	genpromo: ROOT + "/genpromo/",
	settings: ROOT + "/settings/",
	verifysms: ROOT + "/verifysms/",
	isemailavailable: ROOT + "/isemailavailable/",
	registerplans: ROOT + "/registerplans/",
	requestmobverfyotp: ROOT + "/requestmobverfyotp/",
	verifyotp: ROOT + "/verifyotp/",
	getmypic: ROOT + "/getmypic/",
	recipientCheck: ROOT + "/recipientCheck/",
	requestmailresend: ROOT + "/requestmailresend/",
	getsecques: ROOT + "/getsecques/",
	checksecques: ROOT + "/checksecques/",
	addservice: ROOT + "/addservice/",
	removeservice: ROOT + "/removeservice/",
	reqfrwdotp: ROOT + "/reqfrwdotp/",
	checkfrwdotp: ROOT + "/checkfrwdotp/",
	frwdmail: ROOT + "/frwdmail/",
	whitelistmail: ROOT + "/whitelistmail/",
	ref_account: ROOT + "/ref_account/",
	delete_ref: ROOT + "/delete_refcode/",
	approve: ROOT + "/approve/",
	getsecqueslist: ROOT + "/getsecqueslist/",
	changesecques: ROOT + "/changesecques/",
	changepass: ROOT + "/changepass/",
	viewinvoice: ROOT + "/viewinvoice/",
	requestapprovalotp: ROOT + "/requestapprovalotp/",
	getinvoice: ROOT + "/getinvoice/",
	viewcert: ROOT + "/viewcert/",
	payment: ROOT + "/verifyandagreement/",
	convert_to_corporate: ROOT + "/yescorp/",
	getpaymentdata: ROOT + "/getpaymentdata/",
	getdraftmails: ROOT + "/getdraftmails/",
	draftData: ROOT + "/getdraft/",
	viewcertify: ROOT + "/viewcertify/",
};
