import React from "react";
import { useMediaQuery } from "react-responsive";
import './About.css'
const Contact = () => {
	const isMobile = useMediaQuery({
		query: "(max-width: 1024px)",
	});
	return (
		<div className="wrapper">
			<img
				alt="img"
				src={require("../../assets/images/thumb.jpg")}
				style={{
					height: "100vh",
					width: "100%",
					opacity: 0.9,
					position: "absolute",
					zIndex: -1,
				}}
			/>
			<div
				style={{
					display: "flex",
					justifyContent: "flex-end",
					margin: "auto",
					alignItems: "center",
					height: "100vh",
				}}
			>
				<div className="about_card" style={{ width: 700, height: 600, margin: 'auto' }}>
					<div className="about_container">
						<img
							alt="img"
							src={require("../../assets/images/contactus.webp")}
							style={{
								width: "auto",
								height: isMobile ? 200 : 300,
								display: "flex",
								margin: "auto",
							}}
						/>
						<div
							className="contact-us"
						>
							{/* <p>Technical Query: tech@mail.machynworks.in </p> */}
							{/* <p>Customer Support : vcare@mail.machynworks.in</p>{" "} */}
							{/* <p>Business Development : bd@mail.machynworks.in</p>{" "} */}
							<p>Company: M/s Synerthink Solutions LLP</p>
							<p>Address: Gurugram, Haryana, India.</p>
							<p>Email: support@synerthinksolutions.com</p>

							{/* <p>Phone: +91-44-43848585</p> */}
							{/* <div className="contact-office">
								<p>M/s Synerthink Solutions LLP</p>
								<p>
									Gurugram, Haryana, India.
								</p>
							</div> */}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Contact;
